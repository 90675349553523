import React from "react";
import { BsPersonCircle } from "react-icons/bs";

const UserAvatar = () => {
  return (
    <div className="flex items-center justify-center rounded-full bg-gray-300 sm:min-w-14 sm:min-h-14 sm:w-14 sm:h-14 sm:text-3xl min-w-9 min-h-9 w-9 h-9 text-xl font-bold">
      <BsPersonCircle />
    </div>
  );
};

export default UserAvatar;
