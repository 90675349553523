import React from "react";

export default function LabelledInput({
  label,
  value,
  onChange,
  placeholder,
}) {
  return (
    <div className="flex flex-col gap-1 w-full">
      <label htmlFor={label} className="ml-2 text-sm font-semibold">{label}</label>
      <input
        className="bg-primary-green bg-opacity-90 rounded placeholder-black p-4 w-full text-sm outline-none h-11 max-h-11 focus:bg-opacity-50"
        type="text"
        id={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
}
