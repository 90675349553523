import React from "react";
import Loader from "./Loader";
import ServiceButton from "./ServiceButton";

// const exampleServices = [
//   {
//     "name": "General Query About Unherd and its Services",
//     "subtitle": "Need information about the company? I’m here to provide answers and details about our services, subscription plans, and more.",
//     "user_message": "I want to know about your subscription plans."
//   },
//   {
//     "name": "Subscription Cancellation Request",
//     "subtitle": "Want to cancel your subscription? Let me assist you in handling the cancellation process with ease.",
//     "user_message": "I want to cancel my subscription."
//   },
//   {
//     "name": "Update Account Details",
//     "subtitle": "Need to update your personal account details? I can guide you through making changes to keep your account up-to-date.",
//     "user_message": "I want to update my account."
//   },
//   {
//     "name": "New Account Creation",
//     "subtitle": "Looking to create a new account? I’ll help you through the process for a smooth and seamless experience.",
//     "user_message": "I want to create a new account."
//   },
//   {
//     "name": "Login Issues",
//     "subtitle": "Having trouble logging in? I’m here to troubleshoot and resolve any login-related problems.",
//     "user_message": "I'm having trouble logging in."
//   },
//   {
//     "name": "Password Reset",
//     "subtitle": "Forgot your password? No problem! I can assist you in resetting your password securely and quickly.",
//     "user_message": "I want to reset my password."
//   },
//   {
//     "name": "Payment Method Modification",
//     "subtitle": "Need to update your payment method? I’ll ensure your payment details are updated accurately.",
//     "user_message": "I want to update my payment details."
//   },
//   {
//     "name": "Subscription to a Plan",
//     "subtitle": "Interested in subscribing to a plan? Let me walk you through the steps to select the best plan for your needs.",
//     "user_message": "I want to subscribe to a plan."
//   },
//   {
//     "name": "Any Kind of Support Request",
//     "subtitle": "Searching for something specific? I’m here to provide the support you need.",
//     "user_message": "I need help with something else."
//   }
// ];

function organizeServices(services) {
  return services.slice().sort((a, b) => {
    if (a.name.toLowerCase()?.includes('cancel')) {
      return -1;
    } else if (b.name.toLowerCase()?.includes('cancel')) {
      return 1;
    } else if (a.name.toLowerCase()?.includes('password')) {
      return -1
    } else if (b.name.toLowerCase()?.includes('password')) {
      return 1;
    } else if (a.user_message.toLowerCase()?.includes('something else')) {
      return -1
    } else if (b.user_message.toLowerCase()?.includes('something else')) {
      return 1;
    } else {
      return 0;
    }
  });
}


export default function ServicesList({
  n,
  chatbotServices,
  isServicesLoading,
  onServiceSelection,
}) {


  if (isServicesLoading) return <Loader />;
  else return (
    <div className="flex flex-col gap-4 max-w-lg">
      {(
        n !== null && n !== undefined
          ? organizeServices(chatbotServices).slice(0, n)
          : chatbotServices
      )
        .map((service, i) => (
          <ServiceButton
            text={service.user_message}
            onClick={() => onServiceSelection(service.user_message)}
            key={i}
          />
        ))}
    </div>
  );
}
