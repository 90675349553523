import axios from "axios";
import activeChatbotOptions from "./config";

// See issue with more info here:
// https://unherd.atlassian.net/browse/CHATBOT-59?focusedCommentId=10402&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-10402

/*
API Endpoints
GET /available-services
Retrieves a list of available services.
Payload: None

GET /interactions
Fetches all interactions associated with the specified cv_id which is contained in a cross-domain cookie.

POST /chat
Sends a user query to initiate or continue a chat session.
Payload:
{
  "query_text": "string"  // **required**
}

POST /generate-transcript
Generates a transcript for a specific conversation.
Payload:
{
  "cv_id": "string",            // **required**
  "user_email": "string",       // **required**
  "username": "string",         // **required**
  "llm_model": "string",        // default: "gpt-4o"
  "max_words": "integer"        // default: 100
}

POST /record-login-user
Records login details for a user.
Payload:
{
  "email": "string",        // **required**
  "firstname": "string",    // **required**
  "lastname": "string",     // optional,
  "cv_id": "string"         // **required**
}
*/

// Constants
const API_KEY_DEV = process.env.REACT_APP_API_KEY_DEV;
const API_KEY_PROD = process.env.REACT_APP_API_KEY_PROD;
const BASE_URL_DEV = "https://botapidev.unherd.com";
const BASE_URL_PROD = "https://botapi.unherd.com";
const FAILURE_MESSAGE =
  "Sorry, I'm having trouble understanding you right now. Please try again later.";

function getBaseUrl() {
  return activeChatbotOptions.sandbox
    ? `${BASE_URL_DEV}`
    : `${BASE_URL_PROD}`;
}

function getApiKey() {
  return activeChatbotOptions.sandbox
    ? `${API_KEY_DEV}`
    : `${API_KEY_PROD}`;
}

// Axios configuration
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  config.headers.set('x-api-key', getApiKey());
  return config;
});


export class API {
  /*
    GET /available-services
    Response: 
    [
      {
           "name": "Any Kind of Support Request",
           "subtitle": "Searching for something specific? I\u2019m here to provide the support you need.",
           "user_message": "I need help with something else."
      },
      ...
    ]
   */
  static async GetChatbotServices() {
    const url = `${getBaseUrl()}/available-services`;
    try {
      const response = await axios.get(url);
      return response.data.services;
    } catch (error) {
      console.error("Error in GetChatbotServices:", error);
      return [];
    }
  }

  /*
    GET /interactions
    Response:
    {
        "result": {
            "interactions": [
                {
                    "user": "I need help with something else.",
                    "bot": "Of course! I'm here to help. Could you please provide me with more details about what you need assistance with? \ud83d\ude0a"
                },
                {
                    "user": "Hi bot",
                    "bot": "Hello! \ud83d\ude0a How can I assist you today?"
                },
                ...
            ]
        }
    }
   */
  static async GetInteractions() {
    const url = `${getBaseUrl()}/interactions`;
    try {
      const response = await axios.get(url);
      return response.data.result.interactions.reduce((prev, { user, bot }) => prev.concat([
        {
          key: prev.length,
          type: "user",
          text: user,
        },
        {
          key: prev.length + 1,
          type: "bot",
          text: bot,
        }
      ]), []);
    } catch (error) {
      console.error("Error in GetInteractions:", error);
      return [];
    }
  }
  // POST /chat
  static async GetChatbotResponse(queryText) {
    const url = `${getBaseUrl()}/chat`;
    try {
      const response = await axios.post(url, {
        query_text: queryText,
      });
      return response.data.result;
    } catch (error) {
      console.error("Error in GetChatbotResponse:", error);
      return FAILURE_MESSAGE;
    }
  }

  /*
    POST /generate-transcript
    Generates a transcript for a specific conversation.
    Payload:
    {
      "cv_id": "string",            // **required**
      "user_email": "string",       // **required**
      "username": "string",         // **required**
      "llm_model": "string",        // default: "gpt-4o"
      "max_words": "integer"        // default: 100
    }
  */
  static async GenerateTranscript({
    userEmail,
    username,
    llmModel,
    maxWords,
  }) {
    const url = `${getBaseUrl()}/generate-transcript`;
    try {
      const response = await axios.post(url, {
        user_email: "chatbot@coeditor.com",
        username: "Administrator",
        llm_model: llmModel,
        max_words: maxWords,
      });
      return response.data.result;
    } catch (error) {
      console.error("Error in GenerateTranscript:", error);
      return FAILURE_MESSAGE;
    }
  }

  // POST /record-login-user
  static async RecordLoginUser({
    email,
    firstname,
    lastname,
  }) {
    const url = `${getBaseUrl()}/record-login-user`;
    try {
      const response = await axios.post(url, {
        email,
        firstname,
        lastname,
      });
      return response.data;
    } catch (error) {
      console.error("Error in RecordLoginUser:", error);
      return FAILURE_MESSAGE;
    }
  }

  // POST /record-user-login-info-by-wpadmin
  static async RecordUserLoginInfoByWpAdmin({
    email,
    firstname,
    lastname,
    wp_admin_email,
  }) {
    const url = `${getBaseUrl()}/record-user-login-info-by-wpadmin`;
    try {
      const response = await axios.post(url, {
        email,
        firstname,
        lastname,
        wp_admin_email,
      });
      return response.data;
    } catch (error) {
      console.error("Error in RecordUserLoginInfoByWpAdmin:", error);
      return FAILURE_MESSAGE;
    }
  }

  static async EscalateToHumanSupport({
    userEmail,
  }) {
    const url = `${getBaseUrl()}/escalate-to-human-support`;
    try {
      const response = await axios.post(url, {
        user_email: userEmail,
      });
      return response.data?.result;
    } catch (error) {
      console.error("Error in EscalateToHumanSupport:", error);
      return FAILURE_MESSAGE;
    }
  }
}

export default API;
