import React, { useEffect, useRef } from "react";
import BotMessage from "../components/BotMessage";
import UserMessage from "../components/UserMessage";

export default function Messages({ messages, }) {
  const el = useRef(null);
  useEffect(() => {
    el.current.scrollIntoView({ block: "end", behavior: "smooth" });
  });

  return (
    <div className="flex flex-col gap-7 w-full max-w-2xl pt-5">
      {messages.map((m) => m.type === "bot" ? (
        <BotMessage key={m.key} text={m.text} fetchMessage={m.fetchMessage} />
      ) : (
        <UserMessage key={m.key} text={m.text} />
      ))}
      <div id="el" ref={el} className="mt-20" />
    </div>
  );
}

