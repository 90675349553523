import React, { useCallback, useState } from "react";
import { FaTelegramPlane } from "react-icons/fa";

export default function Input({ onSend }) {
  const [text, setText] = useState("");

  const handleInputChange = useCallback((e) => {
    setText(e.target.value);
  }, []);

  const handleSend = useCallback((e) => {
    e.preventDefault();
    onSend(text);
    setText("");
  }, [onSend, text]);

  return (
    <form className="flex items-center gap-5 w-full max-w-2xl bg-transparent" onSubmit={handleSend}>
      <input
        className="bg-primary-green rounded placeholder-black p-4 w-full text-sm outline-none h-11 max-h-11 focus:bg-opacity-50"
        type="text"
        onChange={handleInputChange}
        value={text}
        placeholder="Type your message here."
      />
      <button type="submit" className="flex items-center justify-center rounded-full bg-primary-green min-w-11 min-h-11 w-11 h-11 text-3xl font-bold cursor-pointer hover:bg-opacity-50">
        <FaTelegramPlane size="24px" />
      </button>
    </form>
  );
}
