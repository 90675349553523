import React from "react";
import UserAvatar from "./UserAvatar";

export default function UserMessage({ text }) {
  return (
    <div className="flex justify-end gap-5 ml-10">
      <div className="text-wrap max-w-md w-fit">{text}</div>
      <UserAvatar />
    </div>
  );
}
