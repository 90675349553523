export default function Notice({ onNoticeClick }) {
  return (
    <div
      className="text-xs italic bg-yellow-200 p-2 rounded-lg max-w-2xl mt-2"
      onClick={onNoticeClick}
    >
      <em>
        <strong>Is the chatbot not helping?</strong> This is currently an experimental support channel, if you’d prefer to wrap up this chat and send the conversation to a support agent, simply click <span className="underline cursor-pointer">here</span>.
      </em>
    </div>
  )
}
