import React from "react";

export default function ServiceButton({
  text,
  onClick,
}) {
  return (
    <div
      variant="text"
      className="bg-primary-green text-sm rounded flex items-center p-3 px-4 cursor-pointer hover:bg-opacity-50 h-9 truncate"
      onClick={onClick}
    >
      {text}
    </div>
  );
}
