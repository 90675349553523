import React, { useEffect, useState } from "react";
import BotAvatar from "./BotAvatar";
import Loader from "./Loader";
import { marked } from 'marked';

export default function BotMessage({ fetchMessage, text }) {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getMessage() {
      if (text) {
        setMessage(await marked.parse(text));
      } else if (typeof fetchMessage === "function") {
        try {
          setIsLoading(true);
          const response = await fetchMessage();
          setMessage(await marked.parse(response));
        } catch (error) {
          console.error("Error fetching the message:", error);
          setMessage("Sorry, there was an error fetching the message.");
        }
        setIsLoading(false);
      } else {
        console.error("No message source provided to BotMessage.");
        setMessage("No message to display.");
      }
    }
    getMessage();
  }, [fetchMessage, text]);

  return (
    <div className="flex items-start gap-5 mr-10 w-fit">
      <BotAvatar />
      {isLoading ? (
        <Loader />
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: message }}
          className="text-wrap max-w-md"
        ></div>
      )}
    </div>
  );
}
