import React, { useState, useCallback, useEffect } from "react";
import LabelledInput from "./LabelledInput";
import Loader from "./Loader";

const FIRSTNAME_KEY = "ce-ai-chatbot-firstname";
const LASTNAME_KEY = "ce-ai-chatbot-lastname";
const EMAIL_KEY = "ce-ai-chatbot-email";

export default function WpAdminLogin({ onWpAdminLogin }) {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    const prevEmail = localStorage.getItem(EMAIL_KEY);
    const prevFirstname = localStorage.getItem(FIRSTNAME_KEY);
    const prevLastname = localStorage.getItem(LASTNAME_KEY);
    if (prevEmail !== null && prevFirstname !== null && prevLastname !== null) {
      setEmail(prevEmail);
      setFirstname(prevFirstname);
      setLastname(prevLastname);
    }
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (email === "" || firstname === "" || lastname === "") {
      setErrorText("Please fill in all fields.");
      setIsLoading(false);
      return;
    }

    localStorage.setItem(EMAIL_KEY, email);
    localStorage.setItem(FIRSTNAME_KEY, firstname);
    localStorage.setItem(LASTNAME_KEY, lastname);

    await onWpAdminLogin({
      email: email?.trim(),
      firstname: firstname?.trim(),
      lastname: lastname?.trim(),
    });
    setIsLoading(false);
  }, [onWpAdminLogin, email, firstname, lastname]);

  return (
    <div className="max-w-lg flex flex-col items-center justify-center h-full gap-4">
      <div className="text-center text-xl font-bold">
        Looks like you're a WordPress admin
      </div>
      <div>
        Please provide the details of the user you would like to impersonate:
      </div>
      <LabelledInput
        label="Firstname"
        value={firstname}
        onChange={(e) => setFirstname(e.target.value)}
        placeholder="Enter user firstname"
      />
      <LabelledInput
        label="Lastname"
        value={lastname}
        onChange={(e) => setLastname(e.target.value)}
        placeholder="Enter user lastname"
      />
      <LabelledInput
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter user email address"
      />
      <button
        type="submit"
        className="h-12 flex items-center justify-center rounded bg-primary-green font-bold cursor-pointer hover:bg-opacity-50 p-2 px-4"
        disabled={isLoading}
        onClick={handleSubmit}
      >
        {isLoading ? <Loader /> : "Submit"}
      </button>
      {errorText !== "" && (
        <div className="text-red-500 text-sm">{errorText}</div>
      )}
    </div>
  );
}
