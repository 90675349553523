import React from "react";
import { BsChatLeftFill } from "react-icons/bs";
import OtherServiceOptions from "./OtherServiceOptions";
import ServicesList from "./ServicesList";
import BotMessage from "./BotMessage";

// Note: The end of the line needs to end with two spaces to add a new line.
const introMessage =
  `Welcome! I am designed to assist you with a variety of tasks, quickly and efficiently.  
  
Go on then, give me a try.`;


export default function IntroContent({
  chatbotServices,
  onServiceSelection,
  isServicesLoading,
  setPhase,
}) {
  return (
    <div className="w-full max-w-full sm:max-w-2xl flex flex-col gap-4 p-2 mb-10">
      <BotMessage
        text={introMessage}
      />

      <div className="text-sm">How can I help you today?</div>

      <div
        variant="text"
        className="flex items-center p-2 px-4 bg-primary-green text-sm rounded-2xl max-w-sm w-fit cursor-pointer hover:bg-opacity-50"
        onClick={() => setPhase("services")}
      >
        <BsChatLeftFill />
        <span className="ml-4" >
          Start a new chat
        </span>
      </div>

      <div className="text-sm">Or pick a quick prompt!</div>

      <ServicesList
        n={3}
        chatbotServices={chatbotServices}
        onServiceSelection={onServiceSelection}
        isServicesLoading={isServicesLoading}
      />
      <OtherServiceOptions />
    </div >
  );
}

