import React from "react";

export default function ChatbotNotLoggedIn({
    header,
    text,
}) {
    return (
        <div className="w-full h-full bg-primary-green rounded-lg flex flex-col items-center justify-center gap-5 p-10">
            <div className="text-xl font-bold">{header ?? "Welcome to the AI Chatbot!"}</div>
            <div>{text ?? "Please log in to start chatting."}</div>
        </div>
    );
}
