import React from "react";
import { createRoot } from 'react-dom/client';

import App from './App';

const rootElement = document.getElementById("root");

// Trying to use shadow DOM to isolate the React app from the rest of the page
// This has to be open mode, otherwise react doesn't recognize the element
// const shadowRoot = rootElement.attachShadow({ mode: "open" });
//
// const reactRoot = createRoot(shadowRoot);

const reactRoot = createRoot(rootElement);
reactRoot.render(<App />);


