import React from 'react';
import { BsCurrencyPound, BsExclamationCircleFill } from 'react-icons/bs';
import activeChatbotOptions from '../config';

export default function OtherServiceOptions() {
  return (
    <>
      {(!!activeChatbotOptions.introButton1Url
        || !!activeChatbotOptions.introButton2Url) &&
        <div className="text-sm">Other Options</div>
      }
      <div className="flex flex-col sm:flex-row gap-2 w-full max-w-lg">
        {activeChatbotOptions.introButton1Url && (
          <a
            className="flex flex-row items-center flex-1 cursor-pointer rounded p-2 text-sm bg-[#DAEEF2] text-black h-9"
            href={activeChatbotOptions.introButton1Url}
          >
            <BsCurrencyPound className="mr-2" />
            View subscription plans
          </a>
        )
        }
        {activeChatbotOptions.introButton2Url && (
          <a
            className="flex flex-row items-center flex-1 cursor-pointer rounded p-2 text-sm bg-[#f2e6e7] text-black h-9"
            href={activeChatbotOptions.introButton2Url}
          >
            <BsExclamationCircleFill className="mr-2" />
            FAQs
          </a>
        )}
      </div>
    </>
  );
}
