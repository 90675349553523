import React, { useState, useEffect, useRef, useCallback } from "react";
import "./styles.css";

import ChatbotMain from './components/ChatbotMain';

// Chatbot config & functions
import API from "./ChatbotAPI";
import activeChatbotOptions from "./config";

function App() {
  const [messages, setMessages] = useState([]);
  const [chatbotServices, setChatbotServices] = useState([]);
  const [isServicesLoading, setIsServicesLoading] = useState(false);
  const [phase, setPhase] = useState("intro"); // not-logged-in, wp-admin-form, intro, services, chat
  const [wpAdminData, setWpAdminData] = useState(undefined);
  const [infoBannerText, setInfoBannerText] = useState();
  // const [stylesLoaded, setStylesLoaded] = useState(false);

  const showInfoBanner = useCallback((text) => {
    setInfoBannerText(text);
    setTimeout(() => {
      setInfoBannerText(undefined);
    }, 10000);
  }, [setInfoBannerText]);

  const endChat = useCallback(() => {
    API.GenerateTranscript({
      userEmail: activeChatbotOptions.userData.email,
      username: activeChatbotOptions.userData.firstname,
      llmModel: "gpt-4o",
      maxWords: 100,
    }).then((transcript) => {
      console.info(transcript);
      if (transcript?.result) {
        showInfoBanner(transcript.result);
      }
    });
    setMessages([]);
    // setPhase("intro");
    setWpAdminData(undefined);
  }, [showInfoBanner]);

  const requestHuman = useCallback(() => {
    API.EscalateToHumanSupport({
      userEmail: activeChatbotOptions.userData.email,
    }).then((response) => {
      console.info(response);
      if (response?.result) {
        showInfoBanner(response.result);
      }
    });
    setMessages([]);
    // setPhase("intro");
    setWpAdminData(undefined);
  }, [showInfoBanner]);

  useEffect(() => {
    if (messages.length > 0) {
      setPhase("chat");
    } else if (!activeChatbotOptions.userData.isLoggedIn && !activeChatbotOptions.anonymousWPAdminUse) {
      setPhase("not-logged-in");
    } else if (activeChatbotOptions.anonymousWPAdminUse && wpAdminData === undefined) {
      setPhase("wp-admin-form");
    } else {
      setPhase("intro");
    }
  }, [messages.length, wpAdminData]);


  const inactivityTimers = useRef({ fiveMinTimeout: null, tenMinTimeout: null });

  // Reset inactivity timers when there's user interaction
  const resetInactivityTimers = useCallback(() => {
    if (inactivityTimers.current.fiveMinTimeout) {
      clearTimeout(inactivityTimers.current.fiveMinTimeout);
    }
    if (inactivityTimers.current.tenMinTimeout) {
      clearTimeout(inactivityTimers.current.tenMinTimeout);
    }

    inactivityTimers.current.fiveMinTimeout = setTimeout(() => {
      setMessages((prevMessages) => {
        if (prevMessages.length > 0) {
          return [
            ...prevMessages,
            {
              key: prevMessages.length,
              type: "bot",
              text: "You've been inactive for 5 minutes. The chat will end in 5 minutes if there is no activity.",
            }
          ]
        } else {
          resetInactivityTimers();
          return prevMessages;
        }
      });
    }, 5 * 60 * 1000); // 5 minutes

    inactivityTimers.current.tenMinTimeout = setTimeout(() => {
      setMessages((prevMessages) => {
        if (prevMessages.length > 0) {
          setTimeout(endChat, 3000);
          return [
            ...prevMessages,
            {
              key: prevMessages.length,
              type: "bot",
              text: "You've been inactive for 10 minutes. Ending the session.",
            },
          ];
        }
        else {
          resetInactivityTimers();
          return prevMessages;
        }
      });
    }, 10 * 60 * 1000); // 10 minutes
  }, [endChat]);

  useEffect(() => {

    // Fetch styles
    // (async () => {
    //   let buildPath = 'wp-content/plugins/ai-chatbot-by-coeditor/public/chatbot-ui/build';
    //   let r = await fetch(`/${buildPath}/asset-manifest.json`);
    //   let manifest = await r.json();
    //   let css = manifest.files['main.css'];
    //   // console.log(css);
    //   // let codeRes = await fetch(`${buildPath}/${css}`);
    //   // let code = await codeRes.text();
    //   // console.log(code);
    //   // // inject into shadow DOM
    //   // let style = document.createElement('style');
    //   // style.innerHTML = code;
    //   let shadowRoot = document.getElementById('root').shadowRoot;
    //   let link = document.createElement('link');
    //   link.rel = 'stylesheet';
    //   link.href = `${window.location.href}${buildPath}${css}`;
    //   shadowRoot.prepend(link);
    //   setStylesLoaded(true);
    // })()

    // Setup chatbot services
    setIsServicesLoading(true);
    API.GetChatbotServices().then((chatbotServices) => {
      // Temporary fix for Create Account service
      setChatbotServices(chatbotServices.filter((s) => !s.user_message.toLowerCase().includes("create")));
      setIsServicesLoading(false);
    });

    if (activeChatbotOptions.userData.isLoggedIn || activeChatbotOptions.anonymousWPAdminUse) {
      // Setup interactions
      API.GetInteractions().then((interactions) => {
        if (interactions.length > 0) {
          setPhase("chat");
        }
        setMessages(interactions);
      });
    }

    resetInactivityTimers();
  }, [resetInactivityTimers]);

  const sendMessage = useCallback((text) => {
    resetInactivityTimers();
    try {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          key: prevMessages.length,
          type: "user",
          text: text,
        },
        {
          key: prevMessages.length + 1,
          type: "bot",
          fetchMessage: () => API.GetChatbotResponse(text),
        }
      ]);
    } catch (error) {
      console.error("Error in send:", error);
    }
  }, [resetInactivityTimers]);

  const handleServiceSelection = useCallback((userMessage) => {
    // Fire & forget the login event
    API.RecordLoginUser({
      email: activeChatbotOptions.userData.email,
      firstname: activeChatbotOptions.userData.firstname,
      lastname: activeChatbotOptions.userData.lastname,
    });
    setPhase("chat");
    sendMessage(userMessage);
  }, [sendMessage]);

  const handleWpAdminLogin = useCallback(async ({
    email,
    firstname,
    lastname,
  }) => {
    const response = await API.RecordUserLoginInfoByWpAdmin({
      email,
      firstname,
      lastname,
      wp_admin_email: activeChatbotOptions.userData.wpUserEmail,
    });
    setWpAdminData(response);
  }, []);

  // // console.log(stylesLoaded);
  // if(!stylesLoaded){
  //   return null;
  // }

  return (
    <ChatbotMain
      chatbotServices={chatbotServices}
      isServicesLoading={isServicesLoading}
      messages={messages}
      onEndChat={endChat}
      onSendMessage={sendMessage}
      onServiceSelection={handleServiceSelection}
      phase={phase}
      setPhase={setPhase}
      onWpAdminLogin={handleWpAdminLogin}
      infoBannerText={infoBannerText}
      onRequestHuman={requestHuman}
    />
  );
}

export default App;
