import React from "react";
import { FaChevronLeft, FaDoorOpen } from "react-icons/fa";

import activeChatbotOptions from "../config";

import BotMessage from "./BotMessage";
import ChatbotMainTemplate from "./ChatbotMainTemplate";
import ChatbotNotLoggedIn from './ChatbotNotLoggedIn';
import Input from "./Input";
import IntroContent from "./IntroContent";
import Messages from "./Messages";
import Notice from "./Notice";
import OtherServiceOptions from "./OtherServiceOptions";
import ServicesList from "./ServicesList";
import WpAdminLogin from "./WpAdminLogin";

export default function ChatbotMain({
  chatbotServices,
  isServicesLoading,
  messages,
  onServiceSelection,
  phase,
  setPhase,
  onSendMessage,
  onEndChat,
  onWpAdminLogin,
  infoBannerText,
  onRequestHuman,
}) {
  if (phase === "not-logged-in") {
    return (
      <ChatbotMainTemplate
        onNoticeClick={onRequestHuman}
        content={(
          <ChatbotNotLoggedIn
            header={activeChatbotOptions.notLoggedInHeader}
            text={activeChatbotOptions.notLoggedInText}
          />
        )}
      />
    );
  }
  else if (phase === "wp-admin-form") {
    return (
      <ChatbotMainTemplate
        onNoticeClick={onRequestHuman}
        header={
          !!infoBannerText && (
            <div className="text-sm italic">
              {infoBannerText}
            </div>
          )
        }
        content={(
          <WpAdminLogin onWpAdminLogin={onWpAdminLogin} />
        )}
      />
    );
  }
  else if (phase === "intro") {
    return (
      <ChatbotMainTemplate
        onNoticeClick={onRequestHuman}
        content={(
          <IntroContent
            chatbotServices={chatbotServices}
            onServiceSelection={onServiceSelection}
            isServicesLoading={isServicesLoading}
            setPhase={setPhase}
          />
        )}
      />
    );
  }
  else if (phase === "services") {
    return (
      <ChatbotMainTemplate
        onNoticeClick={onRequestHuman}
        header={(
          <div className="flex items-center justify-between w-full max-w-2xl">
            <div
              className="cursor-pointer text-sm font-semibold rounded p-1 px-4 hover:bg-opacity-50"
              onClick={() => setPhase("intro")}
            >
              <FaChevronLeft />
            </div>
          </div>
        )}
        content={(
          <div className="w-full max-w-full sm:max-w-2xl flex flex-col gap-4 p-2 mb-10">
            <BotMessage
              text={`Hi **${activeChatbotOptions?.userData?.firstname ?? "there"}**! What would you like to do?`}
            />
            <ServicesList
              chatbotServices={chatbotServices}
              onServiceSelection={onServiceSelection}
              isServicesLoading={isServicesLoading}
            />
            <OtherServiceOptions />
          </div>
        )}
      />
    );
  }
  else if (phase === "chat") {
    return (
      <ChatbotMainTemplate
        onNoticeClick={onRequestHuman}
        header={(
          <div className="flex items-center justify-end w-full max-w-2xl">
            <div
              className="flex items-center gap-2 cursor-pointer text-sm font-semibold rounded bg-primary-green p-1 px-4 hover:bg-opacity-50"
              onClick={() => onEndChat()}
            >
              <FaDoorOpen />
              End Chat
            </div>
          </div>
        )}
        content={(
          <Messages messages={messages} />
        )}
        footer={(
          <>
            <Input onSend={onSendMessage} />
            <Notice onNoticeClick={onRequestHuman} />
          </>
        )}
      />
    );
  }
  else {
    return null;
  }
}
