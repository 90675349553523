import LoaderSvg from "./LoaderSvg";

export default function Loader() {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="w-24 h-24">
        <LoaderSvg />
      </div>
    </div>
  )
}
