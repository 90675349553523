/* global chatbotOptions */

// config.js
const defaultChatbotOptions = {
  isPopUp: false, // set to true to test pop up version
  bgcolor: "#E5F0EA",
  textColor: "#000000",
  // chatbotWidth: "800px",
  sandbox: true,
  notLoggedInHeader: "Log in",
  notLoggedInText: "Please log in to start chatting.",

  introButton1Url: "Url",
  introButton2Url: "Url",

  anonymousWPAdminUse: '',

  userData: {
    isLoggedIn: true,
    email: "ios.test@unherd.com",
    firstname: "CoEditor",
    lastname: "Developer",
  },

  wpUserEmail: 'johan.van.eck@coeditor.com',
};

// Use global chatbotOptions if available, otherwise use defaults
const activeChatbotOptions =
  typeof chatbotOptions !== "undefined"
    ? { ...defaultChatbotOptions, ...chatbotOptions }
    : defaultChatbotOptions;

export default activeChatbotOptions;
